import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import './styles.scss';
import { Link } from "gatsby"

const SignUpConfirmation = () => {
	return (
		<Layout>
			<Seo title='Thank You for Signing Up | EMFLAZA® (deflazacort)' />
			<div className='page--sign-up-confirmation'>
				<Hero
					heroImage='hero-confirmation.png'
					heroImageMobile='hero-confirmation-mobile.png'
					title={<>Thank you for signing up! <br/>Please complete your subscription.</>}
					useBreadcrumbs={false}
				></Hero>
				<section className='mt-4 mb-28 md:mt-11'>
					<div className='container'>
						<p className='md:max-w-[508px]'>An email has been sent to the email address you submitted. Please click the confirmation link in that email to complete your subscription.</p>
						<div className='text-center md:text-left'>
							<Link to='/resources' className='btn mt-10 max-w-[243px]'>
								Resources for Your Patients&nbsp;and&nbsp;Practice
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default SignUpConfirmation;
